<template>
<div>
    <!-- navbar -->
    <Navbar />
    <div id="layoutSidenav">
        <Menu />
        <spinner></spinner>
        <div id="layoutSidenav_content">
            <main>
                <header class="page-header page-header-dark bg-gradient-primary-to-secondary mb-4">
                    <div class="container-xl px-4">
                        <div class="page-header-content pt-4">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto mt-4">
                                    <h1 class="page-header-title">
                                        <div class="page-header-icon"><i class="fas fa-search"></i></div>
                                        Consultas
                                    </h1>
                                    <div class="page-header-subtitle">Consultas disponibles en el portal</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <!-- Main page content-->
                <div class="container-xl px-4">
                    <h4 class="mb-0 mt-5">Consultas</h4>
                    <hr class="mt-2 mb-4">
                    <div class="row" v-if="this.tramites.length != 0">
                        <div class="col-md-4 mb-4" v-for="(tramite,index) in this.tramites" :key="index">
                            <CardTramite colorTitle="text-primary" tipo_tramite="consulta" :title="tramite.descripcion" :desc="tramite.portal_leyenda" :url="tramite.portal_strategy" :informacion_modal="tramite.portal_informacion" :defTramiteID="tramite.id" />
                        </div>

                    </div>

                    <div class="row" v-else>
                        <div class="col-md-4 mb-4">
                            <CardTramite colorTitle="text-primary" tipo_tramite="consulta" title="No existen consultas disponibles" desc="" disabled="disabled" />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>

</div>
</template>

<style>

</style>

<script>
import router from '@/router/index.js' // Vue router instance
import spinner from '../../../components/spinner'
import Vue from 'vue'
import Navbar from '../../../components/home/navbar/navbar'
import Menu from '../../../components/home/menu'
import CardButton from '../../../components/home/step/util/cardButton'
import CardTramite from '../../../components/home/step/util/cardTramites'
export default {
    name: "Home",
    components: {
        spinner,
        Navbar,
        Menu,
        CardButton,
        CardTramite
    },
    mounted: function () {
        this.addMenu();
        this.$store.commit("proveedor/SET_ESPECIALIDADES_CLEAR", "");
        this.$store.commit("proveedor/SET_TRAMITE_INGRESADO", "");
        this.getListaTramites();
    },
    methods: {
        addMenu() {
            document.body.classList.remove("sidenav-toggled");
        },
        getListaTramites() {
            this.$store.dispatch("tramiteGenerico/getTramites", "CONSULTA");
        },
    },
    computed: {
        tramites: {
            get() {
                return this.$store.state.tramiteGenerico.tramites;
            }
        },
    },
};
</script>
